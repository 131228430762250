import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {

    getWebLogPage({ state, commit }, params) {
        let promise = httpV2.get2("/official/v1.0/web/log/page/search", params, this);
        return promise;
    },

    replyWeblog({ state, commit }, params) {
        let promise = httpV2.post("/official/v1.0/web/log/reply", params, this);
        return promise;
    },

    /**
     * 导出
     */
    exportWebLogExcel({ state, commit }, params) {
        let promise = httpV2.getFile("/official/v1.0/web/log/export", params, this);
        return promise;
    },

};

export default {
    getters,
    state,
    mutations,
    actions
};